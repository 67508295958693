﻿
.card-asset-bundle {

    .icon {
        margin: auto;
        margin-top: 12px;
        width: 60px;
        font-size: 60px;
    }

    .add-btn, .remove-btn {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .card-title, .MuiCardHeader-title {
        text-align: center;
    }

    .MuiCardMedia-root {
        text-align: center;
    }

    .checkbox {
        position: absolute;
        right: 20px;
        top: 5px;
    }
}

.card-asset-bundle.active {
}

.card-asset-bundle.inactive {
}
