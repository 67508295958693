.unity-player {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 9;
}

.back-button {
    z-index: 11;
    position: fixed;
    top: 5px;
    left: 5px;
}
