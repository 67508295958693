.drone-animated-search {
    animation: 3s ease infinite search-anim;
}

@keyframes search-anim {

    0% {
        transform: translate(0%, 0%);
    }

    25% {
        transform: translate(-20%, -20%);
    }

    50% {
        transform: translate(-20%, 20%);
    }

    75% {
        transform: translate(20%, 20%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}
