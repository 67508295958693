

@mixin drone-animation($delay) {
    animation-name: drone-keyframes;
    animation-duration: 20s;
    animation-delay: $delay;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-origin: 0 0;
}

@keyframes drone-keyframes {
    0% {
        transform: translate(-75px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(-50%, -50%);
    }

    33.33% {
        transform: translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(-50%, -50%);
    }

    66.67% {
        transform: translate(0px, 110px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(-50%, -50%);
    }

    100% {
        transform: translate(-75px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(-50%, -50%);
    }
}

.scanbie-logo {
    background-image: url("/ClientApp/public/images/logo.png");
    background-size: cover;
}


.info-panel-drone {
    position: absolute;
}

.drone-1 {
    @include drone-animation(0s);

    top: 50px;
    left: 10px;
    width: 120px;
    transform: rotate(-10deg);
}

.drone-2 {
    @include drone-animation(-10s);

    top: 120px;
    left: 50px;
    width: 70px;
    transform: rotate(10deg);
}
