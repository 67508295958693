
.full-page {
    .login-form {
        animation: 20s linear infinite translate;
        border: solid white 1px;
        background-color: rgba(0, 0, 0, 0.3);
        background-image: url("/images/star-bg.png");
        border-radius: 25px;
        z-index: 10;

        .form-group, .btn {
            width: 70%;
        }
    }

    .astro {
        position: fixed;
        right: 0;
        bottom: 0;
        max-width: 400px;
        width: 40%;
        z-index: 8;
    }
}

@keyframes translate {

    0% {
        background-position-x: 0px;
    }
    100% {
        background-position-x: 225px;
    }
}