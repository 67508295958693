.native-app-container {
    z-index: 9999;
    background-color: white;
}

.project-loading-indicatior {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
}