﻿
.card-module {
    width: 200px;
    height: 200px;

    .card-img-top, .MuiCardMedia-root {
        margin: auto;
        margin-top: 12px;
        width: 70px;
        height: 70px;
    }

    .MuiCardHeader-title {
        font-size: 18px;
        text-align: center;
    }

    .add-btn, .remove-btn {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .card-title {
        text-align: center;
    }
}


.card-project-user-module {
    width: 200px;

    .card-img-top, .MuiCardMedia-root {
        margin: auto;
        margin-top: 12px;
        width: 70px;
        height: 70px;
    }

    .MuiCardHeader-title {
        font-size: 18px;
        text-align: center;
    }

    .MuiCardContent-root {
        text-align: center;
    }

    .module-roles {
        position: absolute;
        left: 20px;
        top: 5px;
    }
}
