﻿

@mixin project-tile-base() {
    border-radius: 10px;
    transition: all .25s ease;
    user-select: none;
    cursor: pointer;
    min-height: 250px;
    width: 200px;
    overflow: hidden;

    .card-img-top {
        transition: all .25s ease;
    }
    
    &:hover {
        box-shadow: 0 0 10px $primary-color;
        transform: translateY(-2%);

        .card-img-top {
            transform: scale(1.1) translateY(-5%);
        }
    }
}

.project-tile {
    @include project-tile-base();
    background-color: $primary-color;
    color: white;

    .card-title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: 5px;
        padding-right: 5px;
    }

    .project-buttons {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99;

        .project-edit-icon {
            color: white !important;
            text-shadow: 0 0 1px black;
            transition: all .25s ease;

            &:hover {
                text-shadow: 0 0 10px black;
            }
        }
    }

    .card-img-top {
        height: 150px;
        object-fit: cover;
        background-color: white;
    }
}

.create-project-tile {
    
    @include project-tile-base();
}
