@font-face {
    font-family: 'DCAIcons';
    src: url('fonts/DCAIcons.ttf?fs8for') format('truetype'), url('fonts/DCAIcons.woff?fs8for') format('woff'), url('fonts/DCAIcons.svg?fs8for#DCAIcons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'DCAIcons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: inherit;
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 18px;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
        vertical-align: middle;
    }
}

.btn-secondary, .btn-primary, .btn-danger {
    [class^="icon-"], [class*=" icon-"] {
        color: white;
    }
}

.icon-CompassArrow:before {
    content: "\e900";
}

.icon-CompassRings:before {
    content: "\e901";
}

.icon-CompassWheel:before {
    content: "\e902";
}

.icon-DCA:before {
    content: "\e903";
}

.icon-account:before {
    content: "\f004";
}

.icon-arrow-expand:before {
    content: "\f616";
}

.icon-arrow-expand-all:before {
    content: "\f04c";
}

.icon-arrow-expand-horizontal:before {
    content: "\f84d";
}

.icon-arrow-expand-vertical:before {
    content: "\f84e";
}

.icon-arrow-left:before {
    content: "\f04d";
}

.icon-arrow-right:before {
    content: "\f054";
}

.icon-camera:before {
    content: "\f100";
}

.icon-check:before {
    content: "\f12c";
}

.icon-chevron-down:before {
    content: "\f140";
}

.icon-circle-medium:before {
    content: "\f9dd";
}

.icon-clock-outline:before {
    content: "\f150";
}

.icon-close:before {
    content: "\f156";
}

.icon-cloud:before {
    content: "\f15f";
}

.icon-cloud-alert:before {
    content: "\f9df";
}

.icon-cloud-download:before {
    content: "\f162";
}

.icon-cloud-outline:before {
    content: "\f163";
}

.icon-cloud-sync:before {
    content: "\f63f";
}

.icon-code-tags:before {
    content: "\f174";
}

.icon-compass-outline:before {
    content: "\f18c";
}

.icon-content-copy:before {
    content: "\f18f";
}

.icon-cube-outline:before {
    content: "\f1a7";
}

.icon-cursor-move:before {
    content: "\f1b6";
}

.icon-delete:before {
    content: "\f1c0";
}

.icon-delete-forever:before {
    content: "\f5e8";
}

.icon-dots-vertical:before {
    content: "\f1d9";
}

.icon-earth:before {
    content: "\f1e7";
}

.icon-email:before {
    content: "\f1ee";
}

.icon-email-alert:before {
    content: "\f6ce";
}

.icon-email-outline:before {
    content: "\f1f0";
}

.icon-eye:before {
    content: "\f208";
}

.icon-eye-off:before {
    content: "\f209";
}

.icon-fire:before {
    content: "\f238";
}

.icon-fullscreen:before {
    content: "\f293";
}

.icon-fullscreen-exit:before {
    content: "\f294";
}

.icon-google-cardboard:before {
    content: "\f2ae";
}

.icon-help-circle-outline:before {
    content: "\f625";
}

.icon-history:before {
    content: "\f2da";
}

.icon-home:before {
    content: "\f2dc";
}

.icon-image-filter-tilt-shift:before {
    content: "\f2f7";
}

.icon-information:before {
    content: "\f2fc";
}

.icon-information-outline:before {
    content: "\f2fd";
}

.icon-layers:before {
    content: "\f328";
}

.icon-lightbulb-on:before {
    content: "\f6e7";
}

.icon-lock:before {
    content: "\f33e";
}

.icon-lock-alert:before {
    content: "\f8ed";
}

.icon-lock-outline:before {
    content: "\f341";
}

.icon-map-marker:before {
    content: "\f34e";
}

.icon-message-bulleted:before {
    content: "\f6a1";
}

.icon-message-draw:before {
    content: "\f363";
}

.icon-message-image:before {
    content: "\f364";
}

.icon-message-plus:before {
    content: "\f653";
}

.icon-message-processing:before {
    content: "\f366";
}

.icon-message-text:before {
    content: "\f369";
}

.icon-message-video:before {
    content: "\f36b";
}

.icon-nature-people:before {
    content: "\f38f";
}

.icon-palette:before {
    content: "\f3d8";
}

.icon-phone:before {
    content: "\f3f2";
}

.icon-resize:before {
    content: "\fa67";
}

.icon-rocket:before {
    content: "\f463";
}

.icon-rotate-3d:before {
    content: "\f464";
}

.icon-ruler:before {
    content: "\f46d";
}

.icon-settings:before {
    content: "\f493";
}

.icon-shape:before {
    content: "\f830";
}

.icon-shape-plus:before {
    content: "\f495";
}

.icon-share-variant:before {
    content: "\f497";
}

.icon-star:before {
    content: "\f4ce";
}

.icon-star-outline:before {
    content: "\f4d2";
}

.icon-texture:before {
    content: "\f50c";
}

.icon-vector-polygon:before {
    content: "\f560";
}

.icon-vector-polyline:before {
    content: "\f561";
}

.icon-vector-square:before {
    content: "\f001";
}

.icon-view-grid:before {
    content: "\f570";
}

.icon-view-headline:before {
    content: "\f571";
}

.icon-walk:before {
    content: "\f583";
}

.icon-water:before {
    content: "\f58c";
}

.icon-weather-cloudy:before {
    content: "\f590";
}

.icon-weather-night:before {
    content: "\f594";
}

.icon-weather-partlycloudy:before {
    content: "\f595";
}

.icon-weather-pouring:before {
    content: "\f596";
}

.icon-weather-snowy:before {
    content: "\f598";
}

.icon-weather-snowy-rainy:before {
    content: "\f67e";
}

.icon-weather-sunny:before {
    content: "\f599";
}

.icon-weather-sunset-down:before {
    content: "\f59b";
}

.icon-weather-sunset-up:before {
    content: "\f59c";
}

.icon-webpack:before {
    content: "\f72a";
}
