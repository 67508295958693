@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "styles/icons";

$primary-color: #577b84;
$success-color: #40B814;
$btn-color: #fff;

$theme-colors: ( "primary": $primary-color, "danger": #ea4848, "success": $success-color );

$navbar-light-color: #577b84;
$navbar-background-color: rgba(255, 255, 255, 0.5);


#root {
    min-height: 100vh !important;
    display: flex;
    flex-direction: column;
}

html, body {
    min-height: 100vh !important;
    background-color: rgba(244, 244, 244, 1) !important;
}

a {
    &:hover {
        text-decoration: none !important;
    }
}

.scanbie-loading {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: $primary-color;

    h3 {
        color: white;
    }
}

.page-content {
    flex-grow: 1;
    overflow-x: hidden;
/*    height: 100vh;
    width: 100vw;
    display: flex;*/
}

.info-panel {
    flex: 1 0 300px;
    border-right: solid 2px $primary-color;
    width: 300px;
    z-index: 1;

    .MuiDrawer-paperAnchorLeft {
        width: inherit;
    }
}

.MuiPaper-root {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.MuiPopover-paper, .MuiTabs-root, .MuiDrawer-paper, div[role=tabpanel] {
    background-color: white !important;
}

.MuiTable-root {
    background-color: white;
}

.content-panel {
    flex: 3 0 70%;
    overflow-y: auto;
}

.footer {
    flex: 1 0 auto;
}

.navbar-brand {
    height: 40px;
}

.nav-menu {
    .col, .row, .col-auto {
        white-space: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-item {
        .dropdown-item {
            text-align: center !important;
        }

        text-align: center !important;
        background-color: rgba(0, 0, 0, 0.1) !important;
        border-radius: 5px;
        width: 80px;
        height: 60px;
        margin-top: 5px;
        margin-right: 5px;

        .MuiButton-label {
            text-align: center !important;
            display: block;

            svg {
                margin: auto !important;
                display: block;
                font-size: 30px;
            }
        }
    }
}

.btn-primary {
    color: $btn-color !important;
}

.form-label {
    color: $primary-color;
    font-weight: bold;
}

.badge-primary {
    .navbar-text, .nav-link {
        color: $btn-color !important;
    }
}

.icon-btn {
    cursor: pointer;
}

.breadcrumb-item {
    a {
        color: $primary-color !important;
    }
}

.react-toast-notifications__container {
    z-index: 1040 !important;
    overflow: hidden;
}

@keyframes pingBG {
    from {
        background-color: lighten($primary-color, 20%);
    }

    to {
        background-color: initial;
    }
}

.td-order-request {
    animation-duration: 1s;
    animation-name: pingBG;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.markdown {

    blockquote {
        border-left: solid 3px $primary-color;
        color: rgba(0, 0, 0, 0.7);
        padding-left: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: lighten($primary-color, 40%);
        /*    @extend .alert;*/
    }

    h2 {
        text-decoration: underline;
    }
}

.MuiButtonBase-root {
    &:focus {
        outline: none !important;
    }
}

@media (max-width: 768px) {
    .info-panel {
        display: none;
    }

    .page-content {
        flex-grow: unset;
/*        flex: none;*/
        width: 100%;
    }
}

@import "node_modules/bootstrap/scss/bootstrap";
@import "/styles/project";
@import "/styles/module";
@import "/styles/assetbundle";
@import "/styles/scanbie-theme";
