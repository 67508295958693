﻿
h1, h2, h3, h4 {
    color: $primary-color;
}

.profile-image {
    width: 50px;
    height: 50px;
    border: solid 2px $primary-color;
    border-radius: 50%;
    background-size: cover;
}

.profile-dropdown {

    .dropdown-toggle {
        &::after {
            display: none;
        }
    }
}

.scanbie-title {
    background-color: $primary-color !important;
    padding: 5px 10px !important;
    border-radius: 5px !important;
    min-height: initial !important;
    color: white !important;

    .MuiAccordionSummary-content {
        margin: 0 !important;
    }

    .MuiAccordionSummary-expandIcon {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    * {
        color: white !important;
    }
}

.form-control-file {
    display: initial;
    width: initial;
}

.icon-file-select {
    border-radius: 5px;
    transition: all ease 0.5s;

    &:hover {
        opacity: .5;
        cursor: pointer;
        transition: all ease 0.5s;
    }
}

.collaborator-list-item {
    border: solid 1px $primary-color !important;
    border-radius: 5px !important;

    p {
        margin-bottom: 0;
        color: $primary-color;
    }

    .collaborator-icon {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: solid 1px $primary-color;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
    }
}
